import React from "react";

function article_1() {
	return {
		date: "March 17th 2024",
		title: "New portfolio, what now?",
		description:
			"It was about time, but my new portfolio is finally done. ",
		keywords: [
			"New portfolio, what now?",
			"Kars Talens",
			"Kars",
			"Talens",
		],
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">
						After much anticipation and effort, my brand new
						portfolio is finally ready to showcase my journey,
						skills, and projects.
					</div>
					<br />

					<div className="paragraph">
						This portfolio is more than just a collection of pages;
						it's a reflection of my passion for crafting digital
						experiences and solving problems through technology.
					</div>
					<br />
					<div className="paragraph">
						If you're interested in collaborating, have a project in
						mind, or simply want to connect, I encourage you to head
						over to the contact section. I'm always open to new
						opportunities and discussions.
					</div>
					<br />

					<div className="paragraph">
						Feel free to explore my projects, delve into my thoughts
						on the benefits of cloud computing, and get to know more
						about me, Tharindu Nayanajith.
					</div>
				</div>
			</React.Fragment>
		),
	};
}

const myArticles = [article_1];

export default myArticles;
